import React, { useState } from "react";
import { isObject } from "lodash";
import {
    withSubtheme
} from '@starberryComponentsMui';
import Button from '@mui/material/Button';
import ModalPortal from '@myaccount/modals/ModalPortal';
import FsLightbox from 'fslightbox-react';

import defaults from './defaults';

const VirtualTour = withSubtheme((props) => {
    const {
        className,
        virtual,
        showVirtualTour,
        btnLabel,
        btnProps,
    } = props

    let virtualData = virtual+'&nt=1'; // adding the &nt to trigger open in new tab on mobile only https://support.matterport.com/s/articledetail?language=en_US&ardId=kA05d000001DWztCAG
    if (isObject(virtual))
        virtualData = virtual?.url+'&nt=1';

    const [lightboxController, setLightboxController] = useState(false);
    function openLightbox() {
        setLightboxController(!lightboxController);
    }

    if (!virtualData)
        return <></>

    return (
        <>
            {virtual && showVirtualTour &&
                <>
                    <Button classes={{root: className}} onClick={() => openLightbox()} {...btnProps}>{btnLabel}</Button>
                    <ModalPortal>
                        <FsLightbox
                            toggler={lightboxController}
                            sources={[
                                <iframe title="Virtual Tour" src={virtualData} width="100%" height="100%" frameBorder="0" scrolling="no" allowfullscreen style={{ width: '100vw', height: '100vh'}}/>
                            ]}
                        />
                    </ModalPortal>
                </>
            }
        </>
    )
}, 'virtualTour', defaults)

export default VirtualTour
