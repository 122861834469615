export default {
    "props": {
        "showPropertyBeds": true,
        "propertyBedsText": null,
        "propertyBedsSingleText": null,
        "showPropertyBaths": true,
        "propertyBathsText": null,
        "propertyBathsSingleText": null,
        "showPropertyRecep": true,
        "propertyRecText": null,
        "propertyRecSingleText": null,
    },
    "sx": (theme) => ({
        // "marginBottom": theme.spacing(2.25),
        "display": "flex",
        "flexDirection": "row",
        "justifyContent": "space-between",
        // [theme.breakpoints.up('sm')]: {
        //     "flexDirection": "row",
        // },
        "& .roomsItem": {
            "display": "flex",
            "flexDirection": "row",
            "marginRight": theme.spacing(2.25),
            [theme.breakpoints.up('sm')]: {
                "marginRight": theme.spacing(3.25),
            },
            "& svg": {
                "marginRight": theme.spacing(1),
            },
            "& .roomsItem-text": {

            },
        },
    }),
}