import React, { useState } from 'react';
import Carousel from '@myaccount/carousel';
import Typography from "@mui/material/Typography";
import 'react-multi-carousel/lib/styles.css';
import FsLightbox from 'fslightbox-react';
import VideoTour from '@myaccount/property/components/videoTour';
import VirtualTour from '@myaccount/property/components/virtualTour';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import ModalPortal from '@myaccount/modals/ModalPortal';
import SaveProperty from '@myaccount/PropertyResult/SavePropertyWrap';
import { isSold } from '@myaccount/property/utils';

const PropertyImages = props => {
    const {
        property,
        disableLightbox,
        showSavedProperty,
        showVideoTour,
        showVirtualTour,
        linkify,
        flagStatus
    } = props;

    const LinkAnchor = linkify;

    const isSoldProperty = isSold(flagStatus, property.status)

    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        sourceIndex: 0
    });
    function openLightboxOnSlide(number) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            sourceIndex: number
        });
    }

    let imgLightbox = [...property.images]
    if (!imgLightbox.length) {
        imgLightbox = [{
            url:process.env.GATSBY_NO_IMAGE,
            caption: 'caption',
            id: 1
        }]
    }
    let arrImgs = imgLightbox.map((img) => {
        return img.url
    })

    const StatusFlag = ({property, flagStatus}) => {

        if (isSoldProperty) {
            return (
                <Typography className="propertyDetailsFlag">
                    <span>
                        {property.status}
                    </span>
                </Typography>
            )
        }

        return '';
    }

    return(
        <>
            {property?.images &&
                <div style={{"position": "relative", "overflow": "hidden"}}>
                    <StatusFlag property={property} flagStatus={flagStatus} />
                    <Carousel>
                        {imgLightbox.map((img, index) => {
                            let imgUrl = img?.url;
                            if (!imgUrl) {
                                Object.keys(img).map((imgItem, value) => {
                                    imgUrl = img[imgItem]
                                })
                            }
                            return (
                                <>
                                {disableLightbox && LinkAnchor ? ( // Show the carousel with a normal link
                                    <LinkAnchor>
                                        <div className="carouselItemLink">
                                            <div
                                                className="carouselItemDiv"
                                                style={{
                                                    "backgroundImage": `url("${imgUrl}")`
                                                }}
                                                alt={img?.caption}
                                            />
                                        </div>
                                    </LinkAnchor>
                                ) : ( // Show the carousel with the lightbox
                                    <Button
                                        key={img?.id ? img.id : index}
                                        variant="text"
                                        classes={{root: "carouselItemLink"}}
                                        onClick={() => openLightboxOnSlide(index)}
                                    >
                                        <div
                                            className="carouselItemDiv"
                                            style={{
                                                "backgroundImage": `url("${img?.url}")`
                                            }}
                                            alt={img?.caption}
                                        />
                                    </Button>
                                )
                                }

                                </>
                            )
                        })}
                    </Carousel>
                    {!disableLightbox &&
                        <ModalPortal>
                            <FsLightbox
                                toggler={lightboxController.toggler}
                                type="image"
                                sources={arrImgs}
                                sourceIndex={lightboxController.sourceIndex}
                            />
                        </ModalPortal>
                    }
                    <Box sx={{
                        "position": "absolute",
                        "bottom": "15px",
                        "right": "15px",
                        "margin": "0 -7px",
                        "display": "flex",
                        "flexDirection": "row",
                        "zIndex": 5,
                    }}>
                        {showVideoTour && <VideoTour video={property?.video_tour} />}
                        {showVirtualTour && <VirtualTour virtual={property?.virtual_tour} />}
                    </Box>
                    <Box sx={{
                        "position": "absolute",
                        "top": "15px",
                        "right": "15px",
                        "margin": "0 -7px",
                        "display": "flex",
                        "flexDirection": "row",
                        "color": "#fff",
                        "zIndex": 5,
                    }}>
                    {(showSavedProperty && !isSoldProperty) &&
                        <Box className="save-property-in-image" sx={{
                            "display": ["none",null,"block"],
                        }}>
                            <SaveProperty property={property} type="property" />
                        </Box>
                    }
                    </Box>
                </div>
            }
        </>
    )
}

export default PropertyImages

PropertyImages.defaultProps = {
    disableLightbox: false,
    showSavedProperty: false,
    showVideoTour: true,
    showVirtualTour: true
}
