export default {
    "props": {
        "showVirtualTour": true,
        "btnLabel": "360 Tour",
        "btnProps": {
            "size": "small",
            "variant": "white",
        },
    },
    "sx": {
        "margin": "0 7px",
        "width": "auto",
    },
}