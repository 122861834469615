export default {
    "props": {
        "btnLabelProps": "Make an Offer",
        "identityChecks": false,
        "proofOfFundsTitle": "Request to bid",
        "btnProps": {
            "color": "primary",
            "variant": "contained",
            "sx": (theme) => ({
                "width": "100%",
                "marginBottom": theme.spacing(2),
                [theme.breakpoints.up('md')]: {
                    "width": "100%",
                },
                "&.btn-add": {
                    "maxWidth": "300px",
                    "marginBottom": theme.spacing(2),
                }
            })
        }
    },
    "subthemes": {
        "dialogChildren": {
            "props": {
                "successMsgForSale":"Offer posted successfully.",
                "successMsgForLet":"Offer posted successfully."
            },
        }
    }
}
