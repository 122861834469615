import React from "react";

import {
    withSubtheme
} from '@starberryComponentsMui';

import { StarberryIcons } from '@myaccount/icons';

import Typography from "@mui/material/Typography";

import defaults from './defaults';

const PropertyDetailRooms = withSubtheme((props) => {
    const {
        className,
        property,
        propertyBedsText,
        propertyBathsText,
        propertyRecText,
        propertyBedsSingleText,
        propertyBathsSingleText,
        propertyRecSingleText,
        showPropertyBeds,
        showPropertyBaths,
        showPropertyRecep,
    } = props

    return (
        <>
            {(showPropertyBeds && property?.bedroom > 0) || (showPropertyBaths && property?.bathroom > 0) || (showPropertyRecep && property?.reception > 0) ?
                <span
                    className={className}
                >
                    {showPropertyBeds && property?.bedroom > 0 &&
                        <Typography component="span" classes={{root: "roomsItem"}}>
                            <StarberryIcons iconName="propBedsIcon" />
                            <Typography component="span" classes={{root: "roomsItem-text"}}>{property.bedroom}{propertyBedsText && ' ' + (property.bedroom > 1 ? propertyBedsText : propertyBedsSingleText)}</Typography>
                        </Typography>
                    }
                    {showPropertyBaths && property?.bathroom > 0 &&
                        <Typography component="span" classes={{root: "roomsItem"}}>
                            <StarberryIcons iconName="propBathIcon" />
                            <Typography component="span" classes={{root: "roomsItem-text"}}>{property.bathroom}{propertyBathsText && ' ' + (property.bathroom > 1 ? propertyBathsText : propertyBathsSingleText)}</Typography>
                        </Typography>
                    }
                    {showPropertyRecep && property?.reception > 0 &&
                        <Typography component="span" classes={{root: "roomsItem"}}>
                            <StarberryIcons iconName="propRecIcon" />
                            <Typography component="span" classes={{root: "roomsItem-text"}}>{property.reception}{propertyRecText && ' ' + (property.reception > 1 ? propertyRecText : propertyRecSingleText)}</Typography>
                        </Typography>
                    }
                </span>
            : null }
        </>
    )
}, 'propertyDetailsRooms', defaults)

export default PropertyDetailRooms
