import * as React from 'react';
import { withSubtheme } from '@starberryComponentsMui';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import defaults from "./defaults"

const CarouselInit = withSubtheme(props => {
    const {
        children,
        className,
        carouselResponsiveProp,
        deviceType,
        ...rest
    } = props;

    return(
        <Carousel
            responsive={carouselResponsiveProp}
            autoPlay={deviceType !== "mobile" ? true : false}
            deviceType={deviceType}
            className={className}
            {...rest}
        >
            {children}
        </Carousel>
    )
}, 'carousel', defaults)

export default CarouselInit