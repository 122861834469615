import React from "react";
import { withSubtheme } from '@starberryComponentsMui';
import GlobalForm from "@myaccount/forms/";
import { collectFormValues, validateFormFields } from "@myaccount/forms/utils";
import { useFormState } from "@myaccountServices";
import reportFaultFields from "@myaccount/forms/Forms/identityChecks";

import defaults from "./dialogChildrenDefaults";

const DialogChildren = withSubtheme((props) => {

    const { className } = props

    const { state, services } = useFormState()
    let customFields = [...reportFaultFields];

    const [formFields, setFormFields ] = React.useState([...customFields]);

    const ref = React.useRef(null)
    const formStateType = {}
    const messageDialog = { message:state.message, severity:state.error ? 'error' : 'success'}
    const hideForm = (state.success && !state.error)
    const formData = { submitLabel: "Submit" }

    const onSubmit = async(event) => {
        event.preventDefault()

        const formWithValue = validateFormFields(ref, customFields)
        setFormFields(formWithValue);
        // inputRef
        if (ref.current.checkValidity() !== false) {
            let params = {
                form_name: "new_identity_checks",
                successMessage: "Thank you for uploading your proof of funds. We will let you know as soon as it has been reviewed and accepted. You will then be able to bid on this property.",
                multipart: true
            }
            if (props?.postActionData)
                params['postActionData'] = props?.postActionData;

            const values = collectFormValues(ref, params)
            services.postForm(values)
        } else {
            // @todo
        }
    }

    return(
        <div className={className}>
            <GlobalForm
                formFields={formFields}
                handleSubmit={onSubmit}
                formStateType={formStateType}
                messageDialog={messageDialog}
                data={formData}
                ref={ref}
                hideForm={hideForm}
            />
        </div>
    )
}, 'dialogChildren', defaults)

export default DialogChildren
