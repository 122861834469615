import React from "react";
import { useLocation } from "@reach/router";
import { withSubtheme } from '@starberryComponentsMui';
import Button from "@mui/material/Button";
import ActionModalDefault from '@myaccount/modals/default';
import DialogChildren from './dialogChildren';
import defaults from './defaults';
import { FormStoreProvider, useFormState, useAuthState } from "@myaccountServices";
import { doRedirect } from "@myaccount/utils"
import { MYACCOUNT_LOGIN } from "@myaccountUrls";
import CircularProgress from '@myaccount/progress/CircularProgress'
import AddIcon from '@mui/icons-material/Add';
import { storeData } from "@myaccountServices/store/utils"

const CreateTenancyBtnWrap = withSubtheme(props => {
    const {
        btnLabelProps,
        modalTitle,
        btnLabel,
        btnProps,
        property,
        reloadDataFun,
        showAddIcon
        // link,
    } = props

    const { state, services } = useFormState()
    const { services: authServices } = useAuthState()
    const isAuthenticated = authServices.isAuthenticated()
    const location = useLocation();

    const label = btnLabel ? btnLabel : btnLabelProps;

    const [open, setOpen] = React.useState(false);
    const [canReloadData, setCanReloadData] = React.useState(false);

    const handleClickOpen = () => {
        if (!isAuthenticated) {
            storeData('auth_return', location.pathname, 'auth');
            doRedirect(MYACCOUNT_LOGIN);
        } else {
            setOpen(true);
        }
    };

    // if form success then reloadData
    if (state.success && !canReloadData)
        setCanReloadData(true);

    const handleClose = (value) => {
        value.preventDefault();
        services.resetForm();
        if (reloadDataFun && canReloadData) {
            reloadDataFun();
        }
        setOpen(false);
    };

    let dialogContent = '';
    if (state.loading) {
        dialogContent = <CircularProgress />
    } else {
        dialogContent = <DialogChildren
            handleClose={handleClose} property={property}
        />
    }

    return(
        <>
            <Button {...btnProps} className={`btn contact-btn ${showAddIcon ? 'btn-add' : ''}`} onClick={handleClickOpen}>
                {showAddIcon && <AddIcon sx={{ mr: 1 }} /> }
                {label}
            </Button>
            <ActionModalDefault
                open={open}
                handleClickOpen={handleClickOpen}
                handleClose={handleClose}
                property={property}
                children={dialogContent}
                loading={state.loading}
                success={state?.success}
                title={modalTitle ? modalTitle : "Make an Offer"}
            />
        </>
    )
}, 'makeOfferBtn', defaults)

const CreateTenancyBtn = (props) => {
    return (
        <FormStoreProvider>
            <CreateTenancyBtnWrap {...props} />
        </FormStoreProvider>
    )
}

export default CreateTenancyBtn;
