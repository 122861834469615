import React from "react";
import { get, isEmpty } from 'lodash';
import { querystring } from '@myaccountServices/store/utils';
import { useLocation } from "@reach/router";
import { withSubtheme } from '@starberryComponentsMui';
import Button from "@mui/material/Button";
import ActionModalDefault from '@myaccount/modals/default';
import DialogChildren from './dialogChildren';
import DialogChildrenCustom from './dialogChildrenCustom';
import defaults from './defaults';
import { FormStoreProvider, useFormState, useAuthState } from "@myaccountServices";
import { doRedirect } from "@myaccount/utils"
import { MYACCOUNT_LOGIN } from "@myaccountUrls";
import CircularProgress from '@myaccount/progress/CircularProgress'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { getCall } from "@myaccountServices/store/api"
import { isSold } from '@myaccount/property/utils';
import { storeData } from "@myaccountServices/store/utils"

const BookViewingBtnWrap = withSubtheme(props => {
    if (isSold(props?.flag?.status, props?.property?.status)) {
        return <></>
    }

    const {
        btnLabelProps,
        modalTitle,
        btnLabel,
        showBtnIcon,
        btnProps,
        property,
        reloadDataFun,
        apiCallFun,
        customApiCall,
        // link,
    } = props

    const { state, services } = useFormState()
    const { services: authServices } = useAuthState()
    const isAuthenticated = authServices.isAuthenticated()
    const location = useLocation();
    const step = state?.step || 'default';

    const label = btnLabel ? btnLabel : btnLabelProps;

    const [open, setOpen] = React.useState(false);
    const [canReloadData, setCanReloadData] = React.useState(false);

    const handleClickOpen = () => {
        if (!isAuthenticated) {
            storeData('auth_return', location.pathname, 'auth');
            doRedirect(MYACCOUNT_LOGIN);
        } else {
            if (apiCallFun && get(services, `${apiCallFun}`, '') && apiCallFun === 'getAvailabilitySlots') {
                let params = {property_id: property.crm_id}
                services.getAvailabilitySlots(params)
            }
            // looking for custom api calls
            if (!isEmpty(customApiCall)) {
                services.changeFormState({loading: true})
                let params = {id: property.crm_id}
                getCall(`${customApiCall.url}?${querystring(params)}`).then((res) => {
                    if (res.status === 200) {
                        if (isEmpty(res.data.slots)) {
                            services.changeFormState({
                                loading: false,
                                slots: [],
                                error:false,
                                message: ''/*err.response.status*/,
                                step: 'actionCustomSlot',
                                introMessage: ``
                            })
                        } else {
                            services.changeFormState({loading: false, preLoaded: true, slots: res.data})
                        }
                    } else {
                        services.changeFormState({loading: false, slots: [], error:true, message: res.status})
                    }
                }).catch(function(err) {
                    console.log("Error:", err.response);
                    services.changeFormState({loading: false, slots: [], error:true, message: err.status})
                    services.changeFormState({
                        loading: false,
                        slots: [],
                        error:false,
                        message: ''/*err.response.status*/,
                        step: 'actionCustomSlot',
                        introMessage: ``
                    })
                })
            }
            setOpen(true);
        }
    };

    // if form success then reloadData
    if (state.success && !canReloadData)
        setCanReloadData(true);

    const handleClose = (value) => {
        value.preventDefault();
        services.resetForm();
        if (reloadDataFun && canReloadData) {
            reloadDataFun();
        }
        setOpen(false);
    };

    let dialogContent = '';
    if (state.loading) {
        dialogContent = <CircularProgress />
    } else {
        if (step === 'actionCustomSlot') {
            dialogContent = <DialogChildrenCustom
                handleClose={handleClose} property={property}
            />
        } else {
            dialogContent = <DialogChildren
                handleClose={handleClose} property={property}
            />
        }
    }

    const renderDialogs = () => {
        switch (step) {
            case 'actionCustomSlot':
                return(
                    <ActionModalDefault
                        open={open}
                        handleClickOpen={handleClickOpen}
                        handleClose={handleClose}
                        property={property}
                        children={dialogContent}
                        loading={state.loading}
                        success={state?.success}
                        title={modalTitle ? modalTitle : "Update preferred date & time"}
                    />
                )
            default:
                return(
                    <ActionModalDefault
                        open={open}
                        handleClickOpen={handleClickOpen}
                        handleClose={handleClose}
                        property={property}
                        children={dialogContent}
                        loading={state.loading}
                        success={state?.success}
                        title={modalTitle ? modalTitle : "Book a Viewing"}
                    />
                )
        }
    }

    return(
        <>
            <Button {...btnProps} className="btn contact-btn" onClick={handleClickOpen}>{showBtnIcon && <><CalendarTodayIcon fontSize="inherit" />&nbsp;</>}{label}</Button>
            {renderDialogs()}
        </>
    )
}, 'bookViewingBtn', defaults)

const BookViewingBtn = (props) => {
    return (
        <FormStoreProvider>
            <BookViewingBtnWrap {...props} />
        </FormStoreProvider>
    )
}

export default BookViewingBtn;
