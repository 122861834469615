export default {
    "props": {
        "apiCallFun": "getAvailabilitySlots",
        "customApiCall": {},
        "btnLabelProps": "Book a Viewing",
        "showBtnIcon": false,
        "btnProps": {
            "color": "primary",
            "variant": "contained",
        }
    }
}
