/* eslint-disable import/first */

import React from 'react';

import { getRentFrequencyCode } from '@myaccount/helper';
import {
    // CurrencyFormat as _CurrencyFormat,
    useTheme
} from '@starberryComponentsMui';
import { startCase } from 'lodash';
import _CurrencyFormat from './CurrencyCodeFormat';

export const CurrencyFormat = ({theme, search_type, rent_frequency, price_qualifier, ...props}) => {

    const _theme = useTheme();
    theme = theme ?? _theme;

    let pricePrefix, priceSuffix;

    switch (search_type) {
        case "for sale":
        case "sales":
            pricePrefix = startCase(price_qualifier) || theme.getProp("salesPricePrefix");
            priceSuffix = theme.getProp("salesPriceSuffix");
            break;

        case "to let":
        case "lettings":
            pricePrefix = theme.getProp("lettingsPricePrefix");
            priceSuffix = theme.getProp("lettingsPriceSuffix");

            // For letting property we suppose to display rent_frequency
            if (!priceSuffix && rent_frequency) {
                priceSuffix = getRentFrequencyCode(rent_frequency);
            }
            break;

        default:
            // Add in any alternatives here.
            // @todo Should there be some fallback or explicit override,
            // eg. set in the property object?
            break;
    }

    return (<>{pricePrefix} <_CurrencyFormat theme={theme} {...props} />{priceSuffix}</>);
};

export default CurrencyFormat;
