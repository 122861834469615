import ReactDom from "react-dom";

const ModalPortal = ({children}) => {
    // return ReactDom.createPortal(
    //     children,
    //     document.getElementById('modal-portal')
    // )
    const domNode = document.getElementById('modal-portal') || null;
    // If target node precent in dom then do Portal
    if (domNode) {
        return ReactDom.createPortal(
            children,
            domNode
        )
    }
    // or else just return children
    return children

}

export default ModalPortal
