import React from "react";
import { useLocation } from "@reach/router";
import { withSubtheme } from '@starberryComponentsMui';
import Button from "@mui/material/Button";
import Tooltip from '@mui/material/Tooltip';
import ActionModalDefault from '@myaccount/modals/default';
import DialogChildren from './dialogChildren';
import defaults from './defaults';
import { FormStoreProvider, useFormState, useAuthState } from "@myaccountServices";
import { doRedirect } from "@myaccount/utils"
import { MYACCOUNT_LOGIN } from "@myaccountUrls";
import CircularProgress from '@myaccount/progress/CircularProgress'
import NewDocumentBtn from './newBtn';
import AddIcon from '@mui/icons-material/Add';
import { formatPrice } from '@myaccount/helper/action';
import { storeData } from "@myaccountServices/store/utils"

const MakeOfferBtnWrap = withSubtheme(props => {
    const {
        btnLabelProps,
        modalTitle,
        btnLabel,
        btnProps,
        property,
        reloadDataFun,
        identityChecks,
        showAddIcon,
        // link,
    } = props

    const { state, services } = useFormState()
    const { services: authServices } = useAuthState()
    const isAuthenticated = authServices.isAuthenticated()
    const location = useLocation();

    const label = btnLabel ? btnLabel : btnLabelProps;

    const [open, setOpen] = React.useState(false);
    const [canReloadData, setCanReloadData] = React.useState(false);

    React.useEffect(() => {
        if (identityChecks && !state?.allowedActions) {
            services.getActionAllowed({property_id:property.crm_id})
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const handleClickOpen = () => {
        if (!isAuthenticated) {
            storeData('auth_return', location.pathname, 'auth');
            doRedirect(MYACCOUNT_LOGIN);
        } else {
            setOpen(true);
        }
    };

    // if form success then reloadData
    if (state.success && !canReloadData)
        setCanReloadData(true);

    const handleClose = (value) => {
        value.preventDefault();
        services.resetForm();
        if (reloadDataFun && canReloadData) {
            reloadDataFun();
        }
        setOpen(false);
    };

    let dialogContent = '';
    if (state.loading) {
        dialogContent = <CircularProgress />
    } else {
        dialogContent = <DialogChildren
            handleClose={handleClose} property={property}
        />
    }

    if (identityChecks && !state?.allowedActions && state?.loading)
        return <CircularProgress altClass="padding-b-20" />;

    if (identityChecks && !state?.allowedActions)
        return null;

    let dialogContentText = '';
    if (identityChecks && state?.allowedActions) {
        let title = 'Please enter your bid amount.';
        if (state?.allowedActions?.highest_offer) {
            title += `<br />The current highest bid on this property is <b>${formatPrice(state?.allowedActions?.highest_offer)}</b>`;
        } else {
            if (state?.allowedActions?.property_price) {
                title += `<br />The asking price of this property is <b>${formatPrice(state?.allowedActions?.property_price)}</b>`;
            }
        }
        dialogContentText={
            "title": title
        }
    }

    if (identityChecks && state?.allowedActions) {
        const allowedActions = state?.allowedActions;

        // const allowedActions = {
        //   "hasIdentitycheck": false,
        //   "approvedToBid": false,
        //   "hasAcceptedOffer": true,
        //   "hasViewing": true,
        //   "hasOffer": true,
        //   "propertyPrice": 999999,
        //   "priceQualifier": "Asking price",
        //   "highestOffer": 41000000
        // }

        let btnDisabled = true;
        let toolTip = '';
        // if (!allowedActions?.hasViewing || (allowedActions?.hasIdentitycheck && !allowedActions?.approvedToBid))
        //     btnDisabled = true;
        
        if (allowedActions?.approvedToBid && !allowedActions.hasAcceptedOffer && !allowedActions?.hasOffer) {
            btnDisabled = false
        } else if (!allowedActions?.approvedToBid && !allowedActions?.hasIdentitycheck && allowedActions.hasViewing) {
            btnDisabled = false
        }

        if (allowedActions?.hasOffer || allowedActions?.hasAcceptedOffer)
            btnDisabled = true

        // if (allowedActions?.hasIdentitycheck && !allowedActions?.approvedToBid)
        //     toolTip = 'Your proof of funds is under review.';
        // if (!allowedActions?.hasViewing)
        //     toolTip = 'You should have viewed this property to bid. Click on request a viewing.';

        if (allowedActions?.hasIdentitycheck && !allowedActions?.approvedToBid)
            toolTip = "Your proof of funds is under review."
        if (!allowedActions?.hasViewing && !allowedActions?.approvedToBid && !allowedActions?.hasIdentitycheck)
            toolTip = "You should have viewed this property to bid. Click on book a viewing."
        if (allowedActions?.hasOffer)
            toolTip = 'You have already bid on this property. Please visit Bids section for further action.'
        if (allowedActions?.hasAcceptedOffer)
            toolTip = 'Bidding for this property has been closed.';

        if (!allowedActions?.approvedToBid && !allowedActions?.hasIdentitycheck) {
            return (
                <NewDocumentBtn
                    altBtnLabel="Request to bid"
                    altModalTitle="Upload Proof of Funds"
                    altPlusBtn={showAddIcon}
                    showAddIcon={showAddIcon}
                    btnDisabled={btnDisabled}
                    toolTip={toolTip}
                    postActionData={{
                        allowedActions: {
                            ...state?.allowedActions,
                            hasIdentitycheck: true
                        }
                    }}
                    open={open}
                    handleClickOpen={handleClickOpen}
                    handleClose={handleClose}
                />
            )
        } else {
            return (
                <>
                    {btnDisabled && (
                        <Tooltip title={toolTip} arrow placement="top">
                            <div style={{marginBottom: '20px', maxWidth: '310px'}} className="toolTipContent">
                                <Button disabled={btnDisabled || false} {...btnProps} className={`btn contact-btn ${showAddIcon ? 'btn-add' : ''}`} onClick={handleClickOpen}>
                                    {showAddIcon && <AddIcon sx={{ mr: 1 }} /> }
                                    {label}
                                </Button>
                            </div>
                        </Tooltip>
                    )}
                    {!btnDisabled && (
                       <Button disabled={btnDisabled || false} {...btnProps} className={`btn contact-btn ${showAddIcon ? 'btn-add' : ''}`} onClick={handleClickOpen}>
                        {showAddIcon && <AddIcon sx={{ mr: 1 }} /> }
                        {label}
                        </Button>
                    )}
                    <ActionModalDefault
                        open={open}
                        handleClickOpen={handleClickOpen}
                        handleClose={handleClose}
                        property={property}
                        children={dialogContent}
                        loading={state.loading}
                        success={state?.success}
                        title={modalTitle ? modalTitle : "Make an Offer"}
                        dialogContentTextProps={dialogContentText}
                    />
                </>
            )
        }
    }

    return(
        <>
            <Button {...btnProps} className={`btn contact-btn ${showAddIcon ? 'btn-add' : ''}`} onClick={handleClickOpen}>
                {showAddIcon && <AddIcon sx={{ mr: 1 }} /> }
                {label}
            </Button>
            <ActionModalDefault
                open={open}
                handleClickOpen={handleClickOpen}
                handleClose={handleClose}
                property={property}
                children={dialogContent}
                loading={state.loading}
                success={state?.success}
                title={modalTitle ? modalTitle : "Make an Offer"}
                dialogContentTextProps={dialogContentText}
            />
        </>
    )
}, 'makeOfferBtn', defaults)

const MakeOfferBtn = (props) => {
    return (
        <FormStoreProvider>
            <MakeOfferBtnWrap {...props} />
        </FormStoreProvider>
    )
}

export default MakeOfferBtn;
