export default {
    "props": {
        "showVideoTour": true,
        "btnLabel": "Video Tour",
        "lightboxType": "youtube",
        "btnProps": {
            "size": "small",
            "variant": "white",
        },
    },
    "sx": {
        "margin": "0 7px",
        "width": "auto",
    },
}