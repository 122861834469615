export default {
    "props": {
        "carouselResponsiveProp": {
            "desktop": {
                "breakpoint": { "max": 3000, "min": 1280 },
                "items": 1,
                "slidesToSlide": 1 // optional, default to 1.
            },
            "tablet": {
                "breakpoint": { "max": 1279, "min": 769 },
                "items": 1,
                "slidesToSlide": 1 // optional, default to 1.
            },
            "mobile": {
                "breakpoint": { "max": 768, "min": 0 },
                "items": 1,
                "slidesToSlide": 1 // optional, default to 1.
            },
        },
        "swipeable": false,
        "draggable": false,
        "showDots": false,
        "arrows": true,
        "partialVisible": false,
        "ssr": true, // means to render carousel on server-side.
        "infinite": true,
        "autoPlaySpeed": 5000,
        "keyBoardControl": true,
        // "customTransition": "all .5",
        "transitionDuration": 500,
        "containerClass": "carouselContainer",
        // "removeArrowOnDeviceType": ["mobile"],
        "dotListClass": "carouselCustomDots",
        "itemClass": "carouselItemDivParent",
    },
    "sx": (theme) => ({
        "&.carouselContainer": {
            "marginBottom": theme.spacing(3),
            "@media (min-width: 769px)": {
                "marginLeft": `-${theme.spacing(2)}`,
                "marginRight": `-${theme.spacing(2)}`,
            },
            [theme.breakpoints.up('md')]: {
                "marginBottom": theme.spacing(5),
            },
            "& .react-multiple-carousel__arrow": {
                "background": "transparent",
            },
        },
        "& .carouselItemDivParent": {
            "@media (min-width: 769px)": {
                "padding": `0 ${theme.spacing(2)}`,
            }
        },
        "& .carouselItemLink": {
            "display": "block",
            "width": "100%",
            "height": "auto",
            "padding": 0,
            "borderRadius": 0,
            "&:after": {
                "content": "''",
                "position": "absolute",
                "top": 0,
                "left": 0,
                "width": "100%",
                "height": "100%",
                "background": "rgba(0,0,0,0.2)",
                "zIndex": "2",
                "pointerEvents": "none",
            },
            "& .carouselItemDiv": {
                "backgroundSize": "cover",
                "backgroundRepeat": "no-repeat",
                "backgroundPosition": "center center",
                "width": "100%",
                "height": "350px",
                [theme.breakpoints.up('md')]: {
                    "height": "250px",
                },
                [theme.breakpoints.up('lg')]: {
                    "height": "450px",
                },
            },
        },
        "& .carouselCustomDots": {
            "bottom": "15px",
            "@media (min-width: 769px)": {
                "display": "none",
            },
        },
        "& .carouselVideoLinks": {
            "background": "black",
        },
    })
}
