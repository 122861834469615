import React, { useState } from "react";
import { isObject } from "lodash";
import {
    withSubtheme
} from '@starberryComponentsMui';
import Button from '@mui/material/Button';
import ModalPortal from '@myaccount/modals/ModalPortal';
import FsLightbox from 'fslightbox-react';

import defaults from './defaults';

const VideoTour = withSubtheme((props) => {
    const {
        className,
        video,
        showVideoTour,
        lightboxType,
        btnLabel,
        btnProps,
    } = props

    let videoData = video
    if (isObject(video))
        videoData = video?.url

    const [lightboxController, setLightboxController] = useState(false);
    function openLightbox() {
        setLightboxController(!lightboxController);
    }

    if (!videoData)
        return <></>

    return (
        <>
            {videoData && showVideoTour &&
                <>
                    <Button classes={{root: className}} onClick={() => openLightbox()} {...btnProps}>{btnLabel}</Button>
                    <ModalPortal>
                        <FsLightbox
                            toggler={lightboxController}
                            sources={[videoData]}
                            type={lightboxType}
                        />
                    </ModalPortal>
                </>
            }
        </>
    )
}, 'videoTour', defaults)

export default VideoTour
