import React from "react";
import { withSubtheme } from '@starberryComponentsMui';
import Button from "@mui/material/Button";
import ActionModalDefault from '@myaccount/modals/default';
import DialogChildren from '@myaccount/user/proofofFunds/dialogChildren';
import { useFormState } from "@myaccountServices";
import defaults from './defaults';
import CircularProgress from '@myaccount/progress/CircularProgress';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';

const NewDocumentBtn = withSubtheme((props) => {
    const {
        btnLabelProps,
        modalTitle,
        btnLabel,
        altBtnLabel,
        altModalTitle,
        btnProps,
        showPlusBtn,
        altPlusBtn,
        showAddIcon,
        btnDisabled,
        toolTip,
        postActionData,
        open,
        handleClickOpen,
        handleClose
    } = props

    const { state } = useFormState()
    const [canReloadData, setCanReloadData] = React.useState(false);

    const label = altBtnLabel || btnLabel || btnLabelProps;
    const title = altModalTitle || modalTitle || "PROOF OF FUNDS";
    const plusBtn = (altPlusBtn === false) ? altPlusBtn : showPlusBtn

    // if form success then reloadData
    if (state.success && !canReloadData)
        setCanReloadData(true);

    let dialogContent = '';
    if (state.loading) {
        dialogContent = <CircularProgress />
    } else {
        dialogContent = <DialogChildren
            handleClose={handleClose}
            postActionData={postActionData}
        />
    }

    return(
        <>
            {btnDisabled && (
                <Tooltip title={toolTip} arrow placement="top">
                    <div style={{marginBottom: '20px'}} className="toolTipContent">
                        <Button {...btnProps} className={`btn report-fault-btn ${showAddIcon ? 'btn-add' : ''}`} onClick={handleClickOpen} disabled={btnDisabled || false}>
                            {(plusBtn || showAddIcon) && <AddIcon sx={{ mr: 1 }} />}
                            {label}
                        </Button>
                    </div>
                </Tooltip>
            )}
            {!btnDisabled && (
                <Button {...btnProps} className={`btn report-fault-btn ${showAddIcon ? 'btn-add' : ''}`} onClick={handleClickOpen}>
                    {(plusBtn || showAddIcon) && <AddIcon sx={{ mr: 1 }} />}
                    {label}
                </Button>
            )}
            <ActionModalDefault
                open={open}
                handleClickOpen={handleClickOpen}
                handleClose={handleClose}
                children={dialogContent}
                loading={state.loading}
                success={state?.success}
                title={title}
                showPlusBtn={false}
                dialogContentTextProps={{
                    title: "Please upload your proof of funds document. This POF document is required to determine whether you are able to successfully <br />fund the transaction."
                }}
            />
        </>
    )
}, 'newDocumentBtn', defaults)

export default NewDocumentBtn;
