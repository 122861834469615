import React from "react";
import { withSubtheme } from '@starberryComponentsMui';
import GlobalForm from "@myaccount/forms/";
import { collectFormValues, validateFormFields, isRequiredFieldHasValues } from "@myaccount/forms/utils";
import { useFormState } from "@myaccountServices";
import reportFaultFields from "@myaccount/forms/Forms/bookViewing";

import defaults from "../reportFaultBtn/dialogChildrenDefaults";
import { getUserRole, trackViewing } from "@myaccount/helper/eventTracking";

const DialogChildren = withSubtheme((props) => {
    const { className } = props

    const { state, services } = useFormState()
    let customFields = [...reportFaultFields];

    const [formFields, setFormFields ] = React.useState([...customFields]);

    const ref = React.useRef(null)
    const formStateType = {}
    const messageDialog = { message:state.message, severity:state.error ? 'error' : 'success'}
    const hideForm = (state.success && !state.error)
    const formData = { submitLabel: "Book a Viewing" }
    const property = props?.property;

    const onSubmit = event => {
        event.preventDefault()


        const formWithValue = validateFormFields(ref, customFields)
        setFormFields(formWithValue);
        // inputRef
        if (ref.current.checkValidity() !== false && isRequiredFieldHasValues(formWithValue)) {
            const params = {
                property_id: property?.crm_id,
                form_name: "viewing",
                type_id: "VW",
                negotiator_id: property?.negotiator_id,
                // step: step,
                successMessage: props?.successMessage || "Viewing Booked Successfully",
                // ...actionParams.formParams,
                trackData: () => {
                    trackViewing({
                        eventLabel: `Property - ${property?.crm_id}`,
                        eventRole: getUserRole(property)
                    })
                }
            }
            const values = collectFormValues(ref, params)
            services.postForm(values)
        } else {
            // @todo
        }
    }

    return(
        <div className={className}>
            <GlobalForm
                formFields={formFields}
                handleSubmit={onSubmit}
                formStateType={formStateType}
                formState={state}
                messageDialog={messageDialog}
                data={formData}
                ref={ref}
                hideForm={hideForm}
                customParams={{property_id: property.crm_id}}
            />
        </div>
    )
}, 'dialogChildren', defaults)

export default DialogChildren
