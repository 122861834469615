import React from 'react';
import SaveProperty from '@myaccount/PropertyResult/SaveProperty';
import { UserObjectStoreProvider, useUserObjectState, useAuthState } from "@myaccountServices";


const SavePropertyWrapInit = (props) => {

    const { services } = useUserObjectState()
    const { services: authServices } = useAuthState()
    const isAuthenticated = authServices.isAuthenticated()

    React.useEffect(() => {
        if (isAuthenticated)
            services.getUserobject({ state:true })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <SaveProperty {...props} />
    );
}

export const SavePropertyWrap = (props) => {
    return (
        <UserObjectStoreProvider>
            <SavePropertyWrapInit {...props} />
        </UserObjectStoreProvider>
    );
}

export default React.memo(SavePropertyWrap);
